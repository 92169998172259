<template>
  <div class="page login" v-if="!user">
    <LoginForm />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoginForm from '../components/LoginForm.vue';

export default {
  name: 'Login',
  computed: {
    ...mapState(['user']),
  },
  components: {
    LoginForm,
  },
  mounted() {
    if (this.user) {
      this.$router.replace('/profile/polls');
    }
  },
};
</script>

<style scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 1024px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 96px;
}
</style>
